import { render, staticRenderFns } from "./selldetails_table.vue?vue&type=template&id=44813436&scoped=true&"
import script from "./selldetails_table.vue?vue&type=script&lang=js&"
export * from "./selldetails_table.vue?vue&type=script&lang=js&"
import style0 from "./selldetails_table.vue?vue&type=style&index=0&id=44813436&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44813436",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/new-zxj-wap/new-zxj-wap/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('44813436')) {
      api.createRecord('44813436', component.options)
    } else {
      api.reload('44813436', component.options)
    }
    module.hot.accept("./selldetails_table.vue?vue&type=template&id=44813436&scoped=true&", function () {
      api.rerender('44813436', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/selldetails/selldetails_table.vue"
export default component.exports